import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  Row
} from "reactstrap";
import { createSelector } from "reselect";
import {
  getOptionAllCategories,
} from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import artistApi from "api/artistApi";
import PostApi from "api/postApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { formatDateStringToKorea, formatNumberWithCommas } from "helpers/format";
import CountUp from "react-countup";
import { getWallpapers } from "store/post/thunk";
import CreateEditPost from "./CreateEditWallpaper";
import { ENUM_STATUS_FEED, LIST_OPTION_OS, STATUS_FEED_OPTION, TYPE_ARTIST_OPTION } from "helpers/constans";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DropdownOption from "components/Common/DropdownOption";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const CreateEditWallpaper = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const STATUS_FEED_OPTION_LANG = STATUS_FEED_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];


  const TYPE_ARTIST_OPTION_LANG = TYPE_ARTIST_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const LIST_OPTION_OS_LANG = LIST_OPTION_OS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    "condition[title]": withDefault(StringParam, ""),
    "condition[type]": withDefault(StringParam, "card"),
    "condition[status]": withDefault(StringParam, ""),
    "condition[artistIds]": withDefault(StringParam, ""),
    "categoryId": withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ''),
    endDate: withDefault(StringParam, ''),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [itemToEdit, setItemToEdit] = useState<any>(false);

  const [typesSearch, setTypesSearch] = useState<Option[]>([]);

  const [keywordSearch, setKeywordSearch] = useState<string>(query['condition[title]'] || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_FEED_OPTION_LANG?.find((item) => String(item?.value) === String(query['condition[status]'])) || null);
  const [linkImage, setLinkImage] = useState<string>("");
  const [ipAddressSearch, setIPAddressSearch] = useState<string>(query?.ipAddress || "");
  const [nicknameSearch, setNicknameSearch] = useState<string>(query?.nickname || "");
  const [emailSearch, setEmailSearch] = useState<string>(query?.email || "");
  const [oSSearch, setOSSearch] = useState<Option | null>(LIST_OPTION_OS_LANG?.filter((item) => String(item?.value) === String(query?.role))[0]);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.startDate ? moment(query?.startDate || "", 'Y-MM-DD').toDate() : null, query?.endDate ? moment(query?.endDate || "", 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;


  const [artistSearch, setArtistSearch] = useState<any | null>(null);
  const [groupSearch, setGroupSearch] = useState<any | null>(null);

  const [listArtist, setListArtist] = useState<any>([]);
  const [listGroup, setListGroup] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Wallpaper;

  const PostProperties = createSelector(selectLayoutState, (state) => ({
    posts: state.posts,
    isPostLoading: state.isPostLoading,
    isPostSuccess: state.isPostSuccess,

    error: state.error,
  }));

  // Inside your component
  const { isPostLoading } = useSelector(PostProperties);
  const posts = {
    total: 0,
    items: []
  };
  // posts?.items?.length ? posts?.items

  function loadTableData() {
    dispatch(getWallpapers(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      "condition[title]": keywordSearch || '',
      'condition[status]': statusSearch?.value || '',
      "condition[artistIds]": artistSearch?.value || '',
      "categoryId": groupSearch?.value || '',
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[title]": '',
        'condition[status]': '',
        "condition[artist]": '',
        "categoryId": '',
        startDate: '',
        endDate: '',
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setStatusSearch((_prev) => null);
    setTypesSearch((_prev) => []);
    setArtistSearch((_prev: any) => null);
    setGroupSearch((_prev: any) => null);
  };

  // Begin::Change status
  const [itemUpdate, setItemUpdate] = useState<{ id: number | string, status: string | boolean } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdate = (item: { id: number | string, status: string | boolean }) => {
    setItemUpdate(item);
  };
  const handleOnConfirmUpdate = async () => {
    try {
      if (!itemUpdate) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await PostApi?.updatePost(itemUpdate?.id, { status: itemUpdate?.status });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        setItemUpdate((_prev) => null);
        loadTableData();
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End::Change status

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {posts?.total - ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Original Photo"),
        accessor: "photo_url",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;

          return (
            item?.files?.[0]?.url ? <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImage((prev) => item?.files?.[0]?.url)}
                >
                  <img src={item?.files?.[0]?.url} style={{ maxHeight: 80, maxWidth: 90 }} alt="thumbnail" />
                </div>
              </div>
            </> : ""
          )
        },
      },
      {
        Header: t("Download Photo"),
        accessor: "download_url",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;

          return (
            item?.files?.[0]?.url ? <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImage((prev) => item?.files?.[0]?.url)}
                >
                  <img src={item?.files?.[0]?.url} style={{ maxHeight: 80, maxWidth: 90 }} alt="thumbnail" />
                </div>
              </div>
            </> : ""
          )
        },
      },
      {
        Header: t("Avatar"),
        accessor: "avatar",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;

          return (
            item?.files?.[0]?.url ? <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImage((prev) => item?.files?.[0]?.url)}
                >
                  <img src={item?.files?.[0]?.url} style={{ maxHeight: 80, maxWidth: 90 }} alt="thumbnail" />
                </div>
              </div>
            </> : ""
          )
        },
      },
      {
        Header: t("Nickname"),
        accessor: "NickName",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const artist = item?.artists?.[0];
          return (
            <>
              <div className="text-center" style={{ minWidth: '140px' }}>
                {artist ? <>
                  {artist?.name?.ko} <br />
                  {artist?.name?.en} <br />
                  {TYPE_ARTIST_OPTION_LANG?.find((item: any) => String(item?.value) === String(artist?.type))?.label || ''}<br />
                  {t(`${artist?.gender || ''}`)}
                </> : '-'}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "Email",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const category = cell?.value?.length > 0 ? cell?.value?.[0] : '';

          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {category ? <>
                  {category?.title?.ko}<br />
                  {category?.title?.en}
                </> : '-'}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Device Model"),
        accessor: "view",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {formatNumberWithCommas(item?.view)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("OS"),
        accessor: "share",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {formatNumberWithCommas(item?.share)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("IP"),
        accessor: "ip",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {formatNumberWithCommas(item?.download)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Location (Lat Long)"),
        accessor: "download",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {formatNumberWithCommas(item?.download)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Download Time"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{minWidth: '120px'}}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      // {
      //   Header: t("Button Action"),
      //   thClass: "text-center",
      //   thWidth: 150,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul
      //         className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
      //         style={{ width: "120px", justifyContent: "center" }}
      //       >
      //         <TooltipCustom
      //           title={t("Button Update")}
      //           id={`update-md-${item?.id}`}
      //         >
      //           <li className="list-inline-item" id={`update-md-${item?.id}`}>
      //             <Link
      //               className="btn btn-sm btn-soft-primary edit-item-btn"
      //               to="#"
      //               onClick={(e) => {
      //                 e.preventDefault();
      //                 setIsOpen(true);
      //                 setItemToEdit(item);
      //               }}
      //             >
      //               <i className="ri-pencil-fill align-bottom "></i>
      //             </Link>
      //           </li>
      //         </TooltipCustom>

      //         <TooltipCustom
      //           title={t("Button Delete")}
      //           id={`delete-md-${item?.id}`}
      //         >
      //           <li className="list-inline-item" id={`delete-md-${item?.id}`}>
      //             <Link
      //               className="btn btn-sm btn-soft-danger edit-item-btn"
      //               to="#"
      //               onClick={(e) => {
      //                 e.preventDefault();
      //                 setItemToDelete(item);
      //               }}
      //             >
      //               <i className="ri-delete-bin-3-fill align-bottom"></i>
      //             </Link>
      //           </li>
      //         </TooltipCustom>
      //       </ul>
      //     );
      //   },
      // },
    ],
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  // Begin::Delete

  const onCloseImageClick = (e: any) => {
    setLinkImage((_prev) => "");
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await PostApi?.deletePost(id);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // End::Delete

  // Form

  // useEffect(() => {
  //   handleCallAllOption();
  // }, []);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {

        const [resArtist, resGroup]: any = await Promise.all([artistApi.artists({ page: 1, limit: 20000 }), getOptionAllCategories({limit: 2000})]);
        setListArtist((_prev: any) => resArtist?.data?.items || []);
        setListGroup((_prev: any) => resGroup?.data?.items || []);


      } catch (error: any) {

        return error;
      }
    };

    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t("POSTS")} - ${t("POSTS")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Wallpaper Download")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={posts?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        onClick={() => {
                          setIsOpen(true)
                          setItemToEdit(null)
                        }}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t("Create Wallpaper Download")}
                      </button>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <Row className="g-4 align-items-center mt-0 mt-md-2">
                    <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                      <LabelWrapper
                        label={t("Nickname")}
                        isShow={!!nicknameSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Nickname")}...`}
                          value={nicknameSearch}
                          onChange={(e) =>
                            setNicknameSearch(e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className='mt-3 mt-md-2'>
                      <LabelWrapper
                        label={t("Email")}
                        isShow={!!emailSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Email")}...`}
                          value={emailSearch}
                          onChange={(e) =>
                            setEmailSearch(e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className='mt-3 mt-md-2'>
                      <LabelWrapper
                        label={t("IP Address")}
                        isShow={!!ipAddressSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("IP Address")}...`}
                          value={ipAddressSearch}
                          onChange={(e) =>
                            setIPAddressSearch(e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className='mt-sm-4 mt-md-3'>
                      <LabelWrapper label={t('OS')} isShow={true}>
                        <DropdownOption
                          name="os"
                          dataList={LIST_OPTION_OS_LANG || []}
                          placeholder={`${t("OS")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={oSSearch || null}
                          onChangeSelect={(e: any) => setOSSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t('All Filter'), value: '' }}
                        />
                      </LabelWrapper>
                    </Col>
                    
                    <Col sm={12} md={3} xl={3} xxl={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                      <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                        <DatePickerCustom
                          startDate={startDate || null}
                          endDate={endDate || null}
                          onChangePicker={handleChangePicker}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col
                      sm={12} md={12} xl={12} xxl={12}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-sm-4 mt-md-3"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isPostLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-3">
                  <div >
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={posts?.items?.length ? posts?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={posts?.total}
                      customPageCount={Math.ceil(
                        Number(posts?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isPostLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {itemToDelete && (
          <ModalConfirm
            header={t("Delete Wallpaper")}
            isOpen={itemToDelete}
            isLoading={isConfirmLoading}
            onClose={() => setItemToDelete(false)}
            onConfirm={() => handleActionDelete(itemToDelete?._id)}
          />
        )}

        {isOpen && (
          <CreateEditPost
            setIsOpen={setIsOpen}
            item={itemToEdit}
            setItemToEdit={setItemToEdit}
            loadTableData={loadTableData}
            listArtist={listArtist}
            listGroup={listGroup}
            STATUS_FEED_OPTION_LANG={STATUS_FEED_OPTION_LANG}
            setLinkImage={setLinkImage}
          />
        )}

        <Modal
          isOpen={!!linkImage}
          centered={true}
          size="md"
          scrollable={false}
          toggle={onCloseImageClick}
          keyboard={true}
        >
          <ModalBody className="" style={{ maxHeight: '100dvh' }}>
            <img
              src={linkImage || ""}
              className="flex-shrink-0 rounded w-100"
              alt="chart"
            />
          </ModalBody>
        </Modal>

        <ModalConfirm
          textButtonConfirm={t('Button Update')}
          classButtonConfirm='btn-primary'
          classIconButtonConfirm=''
          header={(String(itemUpdate?.status) === ENUM_STATUS_FEED.ACTIVATED) ? t('Confirm to ON') : t('Confirm to OFF')}
          title={t('Are you sure you want to update this?')}
          content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
          isShowIcon={false}
          isOpen={!!itemUpdate}
          isLoading={isLoadingUpdate}
          onClose={() => setItemUpdate((prev: any) => null)}
          onConfirm={handleOnConfirmUpdate}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateEditWallpaper;
