import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  Row
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import artistApi from "api/artistApi";
import SnippetApi from "api/snippetApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { COLOR_STATUS_SNIPPET, ENUM_STATUS_SNIPPET, STATUS_SNIPPET_OPTION, TYPE_ARTIST_OPTION } from "helpers/constans";
import CountUp from "react-countup";
import { getSnippets } from "store/snippet/thunk";
import { getOptionAllCategories } from "store/thunks";
import { truncate } from "utils";
import CreateEditSnippet from "./CreateEditSnippet";
import { formatDateStringToKorea, formatNumberWithCommas } from "helpers/format";
import DropdownArtist from "components/Common/DropdownArtist";
import DropdownGroup from "components/Common/DropdownGroup";
import DatePickerCustom from "components/Common/DatePickerCustom";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const MediaList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const STATUS_SNIPPET_OPTION_LANG = STATUS_SNIPPET_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];


  const TYPE_ARTIST_OPTION_LANG = TYPE_ARTIST_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];


  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    "condition[media_type]": withDefault(StringParam, `video`),
    "condition[title]": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[artistIds]": withDefault(StringParam, ""),
    "categoryId": withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ''),
    endDate: withDefault(StringParam, ''),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [linkImage, setLinkImage] = useState<any>(null);

  const [itemToEdit, setItemToEdit] = useState<any>(false);


  const [keywordSearch, setKeywordSearch] = useState<string>(query['condition[title]'] || "");
  const [shortDescriptionSearch, setShortDescriptionSearch] = useState<string>(query['condition[title]'] || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_SNIPPET_OPTION_LANG?.find((item) => String(item?.value) === String(query['condition[status]'])) || null);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.startDate ? moment(query?.startDate || "", 'Y-MM-DD').toDate() : null, query?.endDate ? moment(query?.endDate || "", 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const [typesSearch, setTypesSearch] = useState<Option[]>([]);


  const [artistSearch, setArtistSearch] = useState<any | null>(null);
  const [groupSearch, setGroupSearch] = useState<any | null>(null);


  const [listArtist, setListArtist] = useState<any>([]);
  const [listGroup, setListGroup] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Snippet;

  const SnippetProperties = createSelector(selectLayoutState, (state) => ({
    snippets: state.snippets,
    isSnippetLoading: state.isSnippetLoading,
    isSnippetSuccess: state.isSnippetSuccess,

    error: state.error,
  }));

  // Inside your component
  const { snippets, isSnippetLoading } = useSelector(SnippetProperties);


  function loadTableData() {
    dispatch(getSnippets(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      "condition[title]": keywordSearch || '',
      'condition[status]': statusSearch?.value || '',
      "condition[artistIds]": artistSearch?.value || '',
      "categoryId": groupSearch?.value || '',
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[title]": '',
        'condition[status]': '',
        "condition[artist]": '',
        "categoryId": '',
        startDate: '',
        endDate: '',
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setTypesSearch((_prev) => []);
    setStatusSearch((_prev) => null);
    setArtistSearch((_prev: any) => null);
    setGroupSearch((_prev: any) => null);
  };

  // Begin::Change status
  const [itemUpdate, setItemUpdate] = useState<{ id: number | string, status: string | boolean } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdate = (item: { id: number | string, status: string | boolean }) => {
    setItemUpdate(item);
  };
  const handleOnConfirmUpdate = async () => {
    try {
      if (!itemUpdate) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await SnippetApi?.updateSnippet(itemUpdate?.id, { status: itemUpdate?.status });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        setItemUpdate((_prev) => null);
        loadTableData();
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End::Change status

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        thClass: 'whitespace-nowrap',
        Cell: ({ ...props }) => {
          return (
            <div>
              {snippets?.total - ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Active"),
        accessor: "status",
        thWidth: 140,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const [currentStatus, setCurrentStatus] = useState(cell?.value);
          return (
            <div className="form-check form-switch form-switch-sm text-center" dir="ltr" style={{minWidth: '120px'}}>
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                id="customSwitchsizelg"
                checked={String(item?.status) === ENUM_STATUS_SNIPPET.ACTIVATED}
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleShowConfirmUpdate({ id: item?._id, status: !!value ? ENUM_STATUS_SNIPPET.ACTIVATED : ENUM_STATUS_SNIPPET.DISABLED });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t('Status'),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${COLOR_STATUS_SNIPPET[status] || 'secondary'}`}>{STATUS_SNIPPET_OPTION_LANG?.find((item: any) => String(item?.value) === String(status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Thumbnail"),
        accessor: "thumbnail_url",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;

          return (
            (cell?.value) ? <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImage({ media_url: item?.media_url, thumbnail_url: cell?.value, media_type: item?.media_type })}
                >
                  <img
                    src={cell?.value}
                    className="flex-shrink-0 rounded w-100"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </> : ""
          )
        },
      },
      {
        Header: t("Title"),
        accessor: "title",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: '200px' }}>{truncate(cell?.value, 70)}</div>
            </>
          );
        },
      },

      {
        Header: t("Content"),
        accessor: "content",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: '200px' }}>{truncate(cell?.value, 70)}</div>
            </>
          );
        },
      },
      {
        Header: t("View"),
        accessor: "viewed",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        thWidth: 150,
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {formatNumberWithCommas(cell?.like)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Artist"),
        accessor: "artist",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const artist = item?.artists?.[0];
          return (
            <>
              <div className="text-center" style={{ minWidth: '140px' }}>
                {artist ? <>
                  {artist?.name?.ko} <br />
                  {artist?.name?.en} <br />
                  {TYPE_ARTIST_OPTION_LANG?.find((item: any) => String(item?.value) === String(artist?.type))?.label || ''}<br />
                  {t(`${artist?.gender || ''}`)}
                </> : '-'}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Group"),
        accessor: "categories",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-center",
        Cell: (cell: any) => {
          const category = cell?.value?.length > 0 ? cell?.value?.[0] : '';

          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>
                {category ? <>
                  {category?.title?.ko}<br />
                  {category?.title?.en}
                </> : '-'}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{minWidth: '120px'}}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{minWidth: '120px'}}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
              style={{ width: "120px", justifyContent: "center" }}
            >
              <TooltipCustom
                title={t("Button Update")}
                id={`update-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`update-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-primary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(true);
                      setItemToEdit(item);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>

              <TooltipCustom
                title={t("Button Delete")}
                id={`delete-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`delete-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-danger edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setItemToDelete(item);
                    }}
                  >
                    <i className="ri-delete-bin-3-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  // Begin::Delete

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await SnippetApi?.deleteSnippet(id);
      console.log(response);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // End::Delete

  // Form

  const onCloseImageClick = (e: any) => {
    setLinkImage(null);
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {

        const [resArtist, resGroup]: any = await Promise.all([artistApi.artists({ page: 1, limit: 20000 }), getOptionAllCategories({limit: 2000})]);
        setListArtist((_prev: any) => resArtist?.data?.items || []);
        setListGroup((_prev: any) => resGroup?.data?.items || []);

      } catch (error: any) {

        return error;
      }
    };

    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t("POSTS")} - ${t("POSTS")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Snap Management")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={snippets?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        onClick={() => {
                          setItemToEdit(null)
                          setIsOpen(true)
                        }}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t("Create Snap")}
                      </button>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <Row className="g-4 align-items-center mt-0 mt-md-2">
                    <Col sm={12} md={3} xl={3} xxl={3} className="mt-sm-4 mt-md-3">
                      <LabelWrapper
                        label={t("Title, Content")}
                        isShow={!!keywordSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Title, Content")}...`}
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Group')} isShow={true}>
                        <DropdownGroup
                          name="group"
                          dataList={listGroup || []}
                          placeholder={`${t("Select Group")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={groupSearch || null}
                          onChangeSelect={(e: any) => setGroupSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t('All Filter'), value: '' }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={4} xl={4} xxl={4} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Artist')} isShow={true}>
                        <DropdownArtist
                          name="artist"
                          dataList={listArtist || []}
                          placeholder={`${t("Select Artist")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={artistSearch || null}
                          onChangeSelect={(e: any) => setArtistSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t('All Filter'), value: '' }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Status')} isShow={true}>
                        <DropdownStatus
                          name="status"
                          dataList={STATUS_SNIPPET_OPTION_LANG || []}
                          placeholder={`${t("Status")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={statusSearch || null}
                          onChangeSelect={(e: any) => setStatusSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t('All Filter'), value: '' }}
                          colors={COLOR_STATUS_SNIPPET}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={4} xl={4} xxl={4} className="mt-sm-4 mt-md-3 date-picker-wrapper-custom">
                      <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                        <DatePickerCustom
                          startDate={startDate || null}
                          endDate={endDate || null}
                          onChangePicker={handleChangePicker}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col
                      sm={12} md={8} xl={8} xxl={8}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-sm-4 mt-md-3"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isSnippetLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col >
                  </Row >
                </div >

                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={snippets?.items?.length ? snippets?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={snippets?.total}
                      customPageCount={Math.ceil(
                        Number(snippets?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isSnippetLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card >
            </Col >
          </Row >
        </Container >
        {itemToDelete && (
          <ModalConfirm
            header={t("Delete Snippet")}
            isOpen={itemToDelete}
            isLoading={isConfirmLoading}
            onClose={() => setItemToDelete(false)}
            onConfirm={() => handleActionDelete(itemToDelete?._id)}
          />
        )}

        {
          isOpen && (
            <CreateEditSnippet
              setIsOpen={setIsOpen}
              item={itemToEdit}
              setItemToEdit={setItemToEdit}
              loadTableData={loadTableData}
              listArtist={listArtist}
              listGroup={listGroup}
              setLinkImage={setLinkImage}
            />
          )
        }

        <Modal
          isOpen={!!linkImage}
          centered={true}
          size="md"
          scrollable={false}
          toggle={onCloseImageClick}
          keyboard={true}
        >
          <ModalBody className="" style={{ maxHeight: '90dvh' }}>
            {
              (linkImage?.media_url && linkImage?.media_type == "video") ? (
                <video
                  width="100%"
                  height="100%"
                  style={{ maxWidth: '800px' }}
                  controls
                  key={linkImage?.media_url}
                  id="lesson-video"
                  autoPlay
                  muted
                >
                  <source src={linkImage?.media_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={linkImage?.thumbnail_url || ""}
                  className="flex-shrink-0 rounded w-100"
                  alt="chart"
                />
              )
            }
          </ModalBody>
        </Modal>
        <ModalConfirm
          textButtonConfirm={t('Button Update')}
          classButtonConfirm='btn-primary'
          classIconButtonConfirm=''
          header={(String(itemUpdate?.status) === ENUM_STATUS_SNIPPET.ACTIVATED) ? t('Confirm to ON') : t('Confirm to OFF')}
          title={t('Are you sure you want to update this?')}
          content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
          isShowIcon={false}
          isOpen={!!itemUpdate}
          isLoading={isLoadingUpdate}
          onClose={() => setItemUpdate((prev: any) => null)}
          onConfirm={handleOnConfirmUpdate}
        />
      </div >
    </React.Fragment >
  );
};

export default MediaList;
