import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import PostApi from "api/postApi";
import { formatQueryParams } from "helpers/format";


export const getPostApi = async (params: any = {}) => {
  try {
    console.log(params)
    const response = await PostApi.posts(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
}

export const getPosts = createAsyncThunk("Posts", getPostApi);
export const getWallpapers = createAsyncThunk("Wallpaper", getPostApi)