import { APIClient } from "./axiosClient";
import config from "../config";
import axios from "axios";

const api = new APIClient();

const path = "/snippets";
const snippetApi = {
  snippets(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

  createSnippet(params: any) {
    return api.post(path, params);
  },

  updateSnippet(id: any, params: any) {
    return api.put(`${path}/${id}`, params);
  },

  deleteSnippet(id: any) {
    return api.delete(`${path}/${id}`);
  },

  summarizePhotoNews(params: any) {
    return api.post(`${path}/summarize`, params);
  },

  getNews(params: any) {
    return api.get(`${path}/news`, params);
  }
};

export function uploadFiles(files: any) {
  const url = "/assets/direct";
  return api.post(url, files, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getListNews(params: any) {
  const url = "https://api.inappnews.net/api/v1/news";

  return axios.post(url, params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export function getDetailNews(id: any) {
  const url = "https://api.inappnews.net/api/v1/news/web";
  
  return axios.get(`${url}/${id}`, {
    params: {
      application_id: "IANCT-016"
    }
  })
}

export default snippetApi;
