import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getDailyUserStatistics as onGetStatistics } from "../../../store/thunks";
import ChartLine from "./ChartLine";


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const Statistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        startDate: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`),
        endDate: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            statistics: state.dailyUserStatistics,
            isStatisticSuccess: state.isDailyUserStatisticSuccess,
            isStatisticLoading: state.isDailyUserStatisticLoading,
            error: state.error,
        })
    );

    const { statistics, isStatisticLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.startDate ? moment(query?.startDate, 'Y-MM-DD').toDate() : null, query?.endDate ? moment(query?.endDate || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetStatistics(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            media_id: mediaSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            startDate: `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`,
            endDate: `${moment().format("Y-MM-DD")}`,
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.startDate || "", 'Y-MM-DD').toDate(), moment(queryNew?.endDate || "", 'Y-MM-DD').toDate()]);
        setMediaSearch({ label: t('All Media'), value: '' });
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 150,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const arrDate = String(cell?.value || '').split(' ');
                    const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
                    const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
                    const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
                    return (
                        !!item?.text_row ? <strong>{item?.text_row} </strong> :
                            <div className="text-start" style={{ minWidth: '120px' }}>
                                <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
                            </div>

                    )
                },
            },
            {
                Header: t('Number of registrations'),
                accessor: "numberOfRegistrations",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 180,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('Number of connections'),
            //     accessor: "numberOfConnections",
            //     filterable: true,
            //     sortable: true,
            //     thClass: 'align-middle text-end',
            //     thWidth: 180,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 <div className="text-end pe-3 " style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value)}</div>
            //             </>
            //         )
            //     },
            // },
            {
                Header: t('Number of comments'),
                accessor: "numberOfComments",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 180,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Number of likes'),
                accessor: "numberOfLikes",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 180,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Number of withdrawals'),
                accessor: "numberOfWithdrawals",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 180,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, query?.media_id]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {

                setIsCallAllOptionLoading((_prev) => false);
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {

        document.title = `${t("Daily users")} - ${t("Statistics")} | Dispatch`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const categories = useMemo(() => {
        return statistics?.data?.map((item: any) => item?.date).reverse() || [];
    }, [statistics?.data, i18n?.language]);

    const series = useMemo(() => {
        const obChart = statistics?.data?.reduce((ob: any, item: any) => {
            ob.registrations?.push(Number(String(item?.numberOfRegistrations).replace(/,/g, '')));
            ob.likes?.push(Number(String(item?.numberOfLikes).replace(/,/g, '')));
            ob.comments?.push(Number(String(item?.numberOfComments).replace(/,/g, '')));
            ob.withdrawals?.push(Number(String(item?.numberOfWithdrawals).replace(/,/g, '')));
            return ob;
        }, { registrations: [], withdrawals: [], likes: [], comments: [], }) || [];
        return (
            [
                {
                    name: t('Number of registrations'),
                    type: 'column',
                    data: obChart?.registrations?.reverse(),
                },
                {
                    name: t('Number of withdrawals'),
                    type: 'line',
                    data: obChart?.withdrawals?.reverse(),
                },
                {
                    name: t('Number of comments'),
                    type: 'line',
                    data: obChart?.comments?.reverse(),
                },
                {
                    name: t('Number of likes'),
                    type: 'line',
                    data: obChart?.likes?.reverse(),
                },
            ]
        )
    }, [statistics?.data, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Daily users')} pageTitle={t('Statistics')} />
                    <Row>
                        <Col sm={12} md={12}>
                            <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                <div className="ribbon round-shape d-none" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">
                                                    <span className="me-2">{t('Total')}:</span>
                                                    <CountUp
                                                        start={0}
                                                        end={statistics?.data?.length || 0}
                                                        duration={1}
                                                        className="text-primary"
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                    <Row className="g-4 align-items-center mt-0">
                                        <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                <DatePickerCustom
                                                    placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                    startDate={startDate || null}
                                                    endDate={endDate || null}
                                                    onChangePicker={handleChangePicker}
                                                    showOptions={[
                                                        'today', 'yesterday', 'two_day_ago',
                                                        'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                        'this_week', 'last_week',
                                                        'this_month', 'last_month',
                                                    ]}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={12} xl={6} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-2 "
                                                    onClick={searchData}
                                                    disabled={(isStatisticLoading || isCallAllOptionLoading)}
                                                >
                                                    {(isStatisticLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                    {t('Button Search')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary fs-14"
                                                    onClick={resetData}
                                                >
                                                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                    {t('Button Reset')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <ChartLine
                                    className="my-3"
                                    titles={[t('Number of registrations'), t('Number of withdrawals'), t('Number of comments'), t('Number of likes')]}
                                    categories={categories}
                                    series={series}
                                />
                                <CardBody className="pt-3">
                                    <TableContainer
                                        className="custom-header-css"
                                        divClass="table-responsive table-card"
                                        tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-daily-statistics"
                                        theadClass="table-light text-muted"
                                        columns={columns}
                                        data={statistics?.data?.length ? (
                                            [
                                                {
                                                    ...statistics?.total,
                                                    text_row: t('Sum')
                                                },
                                                ...statistics?.data]
                                        ) : []}
                                        customPageSize={query.limit}
                                        customPageIndex={query.page - 1}
                                        totalRecords={statistics?.pagination?.total}
                                        customPageCount={Math.ceil(Number(statistics?.pagination?.total) / Number(statistics?.pagination?.limit))}
                                        handleChangePage={handleChangePage}
                                        manualSorting={true}
                                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                        handleChangeSorting={handleChangeSorting}
                                        isLoading={isStatisticLoading}
                                        isShowPagination={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Statistic;
