import { formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";


const ChartLine = ({
  categories = [],
  series = [],
  className = '',
  titles = ['', '', ''],
  enabledDataLabels = false,
}: any) => {
  var options = {
    chart: {
      height: 350,
      toolbar: 'false',
      type: 'line',
      // group: 'social',
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: enabledDataLabels
    },
    stroke: {
      curve: 'straight', // straight
      width: [2, 2, 2, 2],
      dashArray: [0, 0, 0, 0]
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      type: 'category',
      categories: categories,
      labels: {
        show: true,
        formatter: function (val: any) {
          return moment(val).format('MM-DD');
        },
        style: {
          fontSize: "12px",
          fontWeight: 500,
          colors: categories?.map((item: any) => (moment(item).days() === 0 ? '#F06548' : (moment(item).days() === 6 ? '#25A0E2' : ''))), // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
        },
      },
    },
    colors: ['#50cd89', '#009ef7', '#f8285a', '#FF9800', '#546E7A',],
    yaxis: [
      {
        min: 0,
        tickAmount: 5,
        forceNiceScale: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#50cd89'
        },
        labels: {
          formatter: function (val: any) {
            return formatNumberWithCommas(Math.round(val));
          },
          style: {
            colors: '#50cd89',
          }
        },
        opposite: false,
        title: {
          text: titles?.[0] || '',
          style: {
            color: '#50cd89',
          }
        },
        tooltip: {
          enabled: false
        }
      },
      {
        // max: 60000,
        min: 0,
        tickAmount: 5,
        forceNiceScale: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#009ef7'
        },
        labels: {
          formatter: function (val: any) {
            return formatNumberWithCommas(Math.round(val));
          },
          style: {
            colors: '#009ef7',
          }
        },
        opposite: false,
        title: {
          text: titles?.[1] || '',
          style: {
            color: '#009ef7',
          }
        },
        tooltip: {
          enabled: false
        }
      },
      {
        min: 0,
        tickAmount: 5,
        forceNiceScale: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#f8285a'
        },
        labels: {
          formatter: function (val: any) {
            return formatNumberWithCommas(Math.round(val));
          },
          style: {
            colors: '#f8285a',
          }
        },
        opposite: true,
        title: {
          text: titles?.[2] || '',
          style: {
            color: '#f8285a',
          }
        },
        tooltip: {
          enabled: false
        }
      },
      {
        min: 0,
        tickAmount: 5,
        forceNiceScale: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF9800'
        },
        labels: {
          formatter: function (val: any) {
            return formatNumberWithCommas(Math.round(val));
          },
          style: {
            colors: '#FF9800',
          }
        },
        opposite: true,
        title: {
          text: titles?.[3] || '',
          style: {
            color: '#FF9800',
          }
        },
        tooltip: {
          enabled: false
        }
      },
    ],
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
        {
          title: {
            formatter: function (val: any) {
              return val
            }
          },
          formatter: function (val: any) {
            return formatNumberWithCommas(val)
          }
        },
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };
  return (
    <div className="box-apex-charts color-scrollbar-os">
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        height="350"
        className={`apex-charts ${className}`}
      />
    </div>
  );
};

export default ChartLine;