import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = '/statistics';
const statisticApi = {
  dailyUserStatistics(params: any): Promise<any> {
    const url = `${path}/daily-users`;
    return api.get(url, params);
  },
  summaryStatistics(params: any): Promise<any> {
    const url = `${path}/summary`;
    return api.get(url, params);
  },
  hotStatistics(params: any): Promise<any> {
    const url = `${path}/hot`;
    return api.get(url, params);
  },
  wallpaperStatistics(params: any): Promise<any> {
    const url = `${path}/wallpaper`;
    return api.get(url, params);
  },
  photocardNewsStatistics(params: any): Promise<any> {
    const url = `${path}/photocard`;
    return api.get(url, params);
  },
  snapStatistics(params: any): Promise<any> {
    const url = `${path}/snap`;
    return api.get(url, params);
  },
}
export default statisticApi
