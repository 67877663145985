import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import CountUp from "react-countup";
import { getUserAdmins, putUserAdmin } from "store/admin/thunk";
// import { truncate } from "utils";
import AdminApi from "api/adminApi";
// import { Switch } from "antd";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import { COLOR_STATUS_USER, ENUM_STATUS_USER, STATUS_USER_OPTION } from "helpers/constans";
import { formatDateStringToKorea } from "helpers/format";
import UserForm from "./UserForm";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const LIST_OPTION_ROLE = [
  {
    label: "Role_User_Root",
    value: "root",
  },
  {
    label: "Role_User_Admin",
    value: "admin",
  },
  {
    label: "Role_User_Operator",
    value: "operator",
  },
  {
    label: "Role_User_Stage8",
    value: "stage8",
  },
  {
    label: "Role_User_Entertainment",
    value: "entertainment",
  },
  {
    label: "Role_User_Bot",
    value: "bot",
  },
];

const UserAdminList = () => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const { userPermissions } = useRole();

  const STATUS_USER_OPTION_LANG = STATUS_USER_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const LIST_OPTION_ROLE_LANG =
    LIST_OPTION_ROLE?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    // order_by: withDefault(StringParam, `DESC`),
    // date_version: withDefault(StringParam, ''),
    "condition[email]": withDefault(StringParam, ""),
    "condition[role]": withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ''),
    endDate: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ""),
    type: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([query?.startDate ? moment(query?.startDate || "", 'Y-MM-DD').toDate() : null, query?.endDate ? moment(query?.endDate || "", 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [itemToEdit, setItemToEdit] = useState<any>(false);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");
  const [emailSearch, setEmailSearch] = useState<string>(query?.email || "");
  const [roleSearch, setRoleSearch] = useState<Option | null>(LIST_OPTION_ROLE_LANG?.filter((item) => String(item?.value) === String(query?.role))[0]);

  const [typesSearch, setTypesSearch] = useState<Option[]>([]);

  const selectLayoutState = (state: any) => state.Admin;

  const UserAdminProperties = createSelector(selectLayoutState, (state) => ({
    admins: state?.admins,
    isAdminLoading: state?.isAdminLoading,
    isAdminSuccess: state?.isAdminSuccess,

    error: state?.error,
  }));

  // Inside your component
  const { admins, isAdminLoading } = useSelector(UserAdminProperties);

  function loadTableData() {
    dispatch(getUserAdmins(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      "condition[email]": emailSearch || undefined,
      "condition[role]": roleSearch?.value || undefined,
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      keyword: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[email]": undefined,
        "condition[role]": undefined,
        startDate: '',
        endDate: '',
        keyword: undefined,
        domain_ids: undefined,
        sort_by: undefined,
        time_request: +new Date(),
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setTypesSearch((_prev) => []);
    setEmailSearch((_prev) => "");
    setRoleSearch((_prev) => null);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  // Begin::Update User
  const onCloseClick = () => {
    setItemToEdit((_prev: any) => null);
    setIsOpen((_prev) => false);
  };

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(getUserAdmins(query));
  };
  //End::Update User


  // Begin::Change status
  const [itemUpdate, setItemUpdate] = useState<{ id: number | string, status: string | boolean } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdate = (item: { id: number | string, status: string | boolean }) => {
    setItemUpdate(item);
  };
  const handleOnConfirmUpdate = async () => {
    try {
      if (!itemUpdate) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await putUserAdmin(itemUpdate?.id, { status: itemUpdate?.status });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        setItemUpdate((_prev) => null);
        dispatch(getUserAdmins(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End::Change status

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {admins?.total - ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Name"),
        accessor: "fistName",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div>
                {item?.fistName || ""} {item?.lastName || ""}
              </div>
            </>
          );
        },
      },

      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer">{cell?.value}</div>
            </>
          );
        },
      },

      {
        Header: t("Role"),
        accessor: "role",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const role = LIST_OPTION_ROLE_LANG.filter((item) => item.value == cell?.value)[0] || '';
          return (
            <>
              <div className="cursor-pointer">{role?.label}</div>
            </>
          );
        },
      },
      {
        Header: t('Status'),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${COLOR_STATUS_USER[status] || 'secondary'}`}>{STATUS_USER_OPTION_LANG?.find((item: any) => String(item?.value) === String(status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Active"),
        accessor: "status",
        thWidth: 140,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                id="customSwitchsizelg"
                checked={String(item?.status) === ENUM_STATUS_USER.ACTIVATED}
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleShowConfirmUpdate({ id: item?._id, status: !!value ? ENUM_STATUS_USER.ACTIVATED : ENUM_STATUS_USER.DISABLED });
                }}
              />
            </div>
          );
        }
      },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
              style={{ width: "120px", justifyContent: "center" }}
            >
              <TooltipCustom
                title={t("Button Update")}
                id={`update-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`update-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-primary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(true);
                      setItemToEdit(item);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>

              <TooltipCustom
                title={t("Button Delete")}
                id={`delete-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`delete-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-danger edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setItemToDelete(item);
                    }}
                  >
                    <i className="ri-delete-bin-3-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Begin::Delete

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await AdminApi?.deleteUserAdmin(id);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // End::Delete

  useEffect(() => {
    document.title = `${t("Admin User")} - ${t("User")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Admin User Management")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t('Total')}:</span>
                          <CountUp
                            start={0}
                            end={admins?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>

                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success me-1"
                          onClick={() => setIsOpen((_prev) => true)}
                        >
                          <i className="ri-add-fill align-bottom me-1"></i>{" "}
                          {t("Button Create User")}
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <Row className="g-4 align-items-center mt-0 mt-md-2">
                    <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                      <LabelWrapper
                        label={t("Name")}
                        isShow={!!keywordSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Name")}...`}
                          value={keywordSearch}
                          onChange={(e) =>
                            setKeywordSearch(e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                      <LabelWrapper
                        label={t("Email")}
                        isShow={!!emailSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Email")}...`}
                          value={emailSearch}
                          onChange={(e) =>
                            setEmailSearch(e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Role')} isShow={!!roleSearch?.value}>
                        <DropdownOption
                          name="role"
                          dataList={LIST_OPTION_ROLE_LANG || []}
                          placeholder={`${t("Role")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={roleSearch || null}
                          onChangeSelect={(e: any) => setRoleSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t('All Role'), value: '' }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={12} md={3} xl={3} xxl={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                      <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                        <DatePickerCustom
                          startDate={startDate || null}
                          endDate={endDate || null}
                          onChangePicker={handleChangePicker}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-sm-4 mt-md-3"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>

                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={admins?.items?.length ? admins?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={admins?.total}
                      customPageCount={Math.ceil(
                        Number(admins?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isAdminLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={!!itemToEdit?._id || isOpen}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>
              {!!itemToEdit?._id
                ? t("Button Update User")
                : t("Button Create User")}
            </>{" "}
            <>
              {itemToEdit?._id && (" - " + (itemToEdit?.nickname || "") || "")}
            </>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <UserForm
                isModal={true}
                id={String(itemToEdit?._id || "")}
                isCopy={!!itemToEdit?.isCopy}
                triggerRefresh={triggerRefresh}
                LIST_OPTION_ROLE={LIST_OPTION_ROLE_LANG}
              />
            </div>
          </ModalBody>
        </Modal>

        {itemToDelete && (
          <ModalConfirm
            header={t("Delete User")}
            isOpen={itemToDelete}
            isLoading={isConfirmLoading}
            onClose={() => setItemToDelete(false)}
            onConfirm={() => handleActionDelete(itemToDelete?._id)}
          />
        )}

        <ModalConfirm
          textButtonConfirm={t('Button Update')}
          classButtonConfirm='btn-primary'
          classIconButtonConfirm=''
          header={(String(itemUpdate?.status) === ENUM_STATUS_USER.ACTIVATED) ? t('Confirm to ON') : t('Confirm to OFF')}
          title={t('Are you sure you want to update this?')}
          content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
          isShowIcon={false}
          isOpen={!!itemUpdate}
          isLoading={isLoadingUpdate}
          onClose={() => setItemUpdate((prev: any) => null)}
          onConfirm={handleOnConfirmUpdate}
        />
      </div>
    </React.Fragment>
  );
};

export default UserAdminList;
