import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { AxiosResponse } from "axios";
import { IUser } from "./types/_user";
const api = new APIClient();

const path = "/countries";
const artistApi = {
  countries(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
 
};

export default artistApi;
